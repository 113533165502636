<p-toast key="notification" position="top-right"></p-toast>

<div class="d-flex">
  @if (isAuthenticated) {
    <app-sidebar></app-sidebar>
  }
  <div class="content">
    @if (isAuthenticated) {
      <app-breadcrumb></app-breadcrumb>
    }
    <div class="p-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
