import {Component} from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: [],
  standalone: true,
})
export class Error404Component {
}
