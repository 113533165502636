import {Component} from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: [],
  standalone: true,
})
export class UnauthorizedComponent {
}
